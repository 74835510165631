import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import PureLayout from 'layouts/pure';

// ----------------------------------------------------------------------

const PhotoEditorPage = lazy(() => import('pages/view/photo/editor'));

// ----------------------------------------------------------------------

export const pureRoutes = [
    {
        element: (
            <PureLayout>
                <Outlet />
            </PureLayout>
        ),
        children: [{ path: 'photo/:photoId/:userId', element: <PhotoEditorPage /> }]
    }
];
