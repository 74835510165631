import { m } from 'framer-motion';
// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from 'auth/hooks';
// assets
import { ForbiddenIllustration } from 'assets/illustrations';
// components
import { MotionContainer, varBounce } from 'components/animate';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
    categoryKey: string;
    destinationKey?: string;
    children: React.ReactNode;
    sx?: SxProps<Theme>;
};

export default function RoleBasedGuard({ categoryKey, destinationKey, children, sx }: RoleBasedGuardProp) {
    // Logic here to get current user role
    const { user } = useAuthContext();

    const isAlowed = useMemo(() => {
        if (user && user.role) {
            const permission = { ...user.permission };
            if (permission[categoryKey]) {
                if (destinationKey) {
                    return permission[categoryKey][destinationKey];
                }
                return !!Object.values(permission[categoryKey]).filter((e) => e).length;
            }
            return false;
        }
        return false;
    }, [user, categoryKey, destinationKey]);

    if (!isAlowed) {
        return (
            <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
                <m.div variants={varBounce().in}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                        Permission Denied
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <Typography sx={{ color: 'text.secondary' }}>
                        You do not have permission to access this page
                    </Typography>
                </m.div>

                <m.div variants={varBounce().in}>
                    <ForbiddenIllustration
                        sx={{
                            height: 260,
                            my: { xs: 5, sm: 10 }
                        }}
                    />
                </m.div>
            </Container>
        );
    }

    return <> {children} </>;
}
