// @mui
import Box from '@mui/material/Box';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function PureLayout({ children }: Props) {
    return (
        <Box component="main" sx={{ bgcolor: 'black', height: 1 }}>
            {children}
        </Box>
    );
}
